import React, { useCallback, useState } from "react";
import {
  CONFIRM_CONTEXT_DELETE,
  CONTEXT_DELETE,
  createMessage,
} from "ee/constants/messages";
import { MenuItem } from "@appsmith/ads";
import { deleteAction } from "actions/pluginActionActions";
import { useDispatch } from "react-redux";
import { usePluginActionContext } from "PluginActionEditor";

const DeleteWorkflowActionMenuItem = () => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dispatch = useDispatch();
  const { action } = usePluginActionContext();

  const onDeleteClick = useCallback(() => {
    if (!confirmDelete) {
      setConfirmDelete(true);

      return;
    }

    dispatch(deleteAction({ id: action.id, name: action.name }));
  }, [action.id, action.name, confirmDelete, dispatch]);

  return (
    <MenuItem
      className="t--apiFormDeleteBtn error-menuitem"
      onSelect={onDeleteClick}
      startIcon="trash"
    >
      {confirmDelete
        ? createMessage(CONFIRM_CONTEXT_DELETE)
        : createMessage(CONTEXT_DELETE)}
    </MenuItem>
  );
};

export default DeleteWorkflowActionMenuItem;
