import React from "react";
import {
  PluginActionEditor,
  PluginActionForm,
  PluginActionResponse,
} from "PluginActionEditor";
import WorkflowPluginActionToolbar from "ee/pages/Editor/WorkflowEditor/WorkflowPluginActionEditor/components/WorkflowPluginActionToolbar";

const WorkflowPluginActionEditor = () => {
  return (
    <PluginActionEditor>
      <WorkflowPluginActionToolbar />
      <PluginActionForm />
      <PluginActionResponse />
    </PluginActionEditor>
  );
};

export default WorkflowPluginActionEditor;
