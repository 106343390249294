import React from "react";
import {
  PluginActionEditor,
  PluginActionForm,
  PluginActionResponse,
} from "PluginActionEditor";
import ModulePluginActionToolbar from "ee/pages/Editor/ModuleEditor/ModulePluginActionEditor/components/ModulePluginActionToolbar";

const ModulePluginActionEditor = () => {
  return (
    <PluginActionEditor>
      <ModulePluginActionToolbar />
      <PluginActionForm />
      <PluginActionResponse />
    </PluginActionEditor>
  );
};

export default ModulePluginActionEditor;
